<template>
  <div>
    <AdReportingDeviceAnalytic
      :device="device"
      :get-loaded-device="deviceLoad"
    />
    <b-card
      class="p-0"
    >
      <b-card-title class="p-0 mb-0 font-weight-bold">
        {{ 'Total Revenue $ '+ totalRevenue }}
      </b-card-title>
    </b-card>
    <b-skeleton-wrapper
      :loading="spinner"
      class="mt-2"
    >
      <template #loading>
        <b-card>
          <b-skeleton-table
            v-if="spinner"
            :rows="5"
            :columns="5"
            :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
          />
        </b-card>
      </template>
      <b-table
        responsive
        :per-page="paginationObject.limit"
        :items="ads"
        :fields="fields"
        show-empty
        empty-text="No matching records found"
        class="mt-2"
      >
        <template #cell(device_name)="data">
          <b-link class="font-weight-bolder text-primary">
            {{ data.item.device_name }}
          </b-link>
        </template>
        <template #cell(group_name)="data">
          <div class="text-nowrap">
            <span
              class="font-weight-bolder text-primary"
            >
              {{ data.item.group_name }}
            </span>
          </div>
        </template>
        <template #cell(played_at)="data">
          <span class="font-weight-light text-primary">
            {{ time(data.item.played_at) }}
          </span>
        </template>
        <template #cell(revenue)="data">
          <h6 class="font-weight-bolder text-primary">
            $ {{ data.item.revenue ? data.item.revenue : '0' }}
          </h6>
        </template>
        <template #cell(ssp_ad_id)="data">
          <h6 class="font-weight-bolder text-primary">
            {{ data.item.ssp_ad_id ? data.item.ssp_ad_id :"0" }}
          </h6>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <CustomPagination
          v-if="ads.length"
          :total="paginationObject.total"
          :page="paginationObject.offset"
          :per-page="paginationObject.limit"
          @perPageChanged="perPageChanged"
          @pageChanged="pageChanged"
        />
      </div>
    </b-skeleton-wrapper>
  </div>
</template>
<script >
import {
  BTable, BSkeletonTable, BSkeletonWrapper, BCard, BLink, BCardTitle,
} from 'bootstrap-vue'
import CustomPagination from '@/components/common/CustomPagination.vue'
import { showToast, timeConverter } from '@/common/global/functions'
import AccountTypes from '@/common/enums/accountTypeEnum'
import AdReportingDeviceAnalytic from '@/components/ad-reporting/AdReportingDeviceAnalytic.vue'

export default {
  components: {
    BTable,
    BSkeletonTable,
    BSkeletonWrapper,
    BCard,
    BLink,
    BCardTitle,
    CustomPagination,
    AdReportingDeviceAnalytic,
  },
  data() {
    return {
      spinner: false,
      AccountTypes,
      deviceLoad: false,
      device: {
        ssp_opt_in: Boolean,
        id: Number,
        device_address: String,
        impressions: String,
        status: String,
        contact_email: String,
        images: Array,
        op_start_time: String,
        op_end_time: String,
        height: Number,
        width: Number,
        cpm: String,
        name: String,
        last_upadated_date: String,
        video_support: Boolean,
        image_support: Boolean,
        latitude: Number,
        longitude: Number,
        user_has_group: {
          name: String,
        },
        venue: {
          type: {
            venue_type_name: String,
          },
        },
      },
      fields: [
        {
          key: 'device_name',
          label: 'SCREEN NAME',
        },
        {
          key: 'group_name',
          label: 'BUSINESS NAME',
        },
        {
          key: 'ssp_type',
          label: 'SSP TYPE',
        },
        {
          key: 'played_at',
          label: 'PLAYED AT',
        },
        {
          key: 'revenue',
          label: '($) REVENUE',
        },
        {
          key: 'ssp_ad_id',
          label: 'SSP ID',
        },
      ],
      items: [
        {
          device_name: 'qwe',
          group_name: 'AC',
          device_id: 45,
          revenue: '22',
          ssp_type: 'vistar',
        },
      ],

    }
  },
  computed: {
    ads() {
      return this.$store.getters['reports/getAdsData']
    },
    totalRevenue() {
      return this.$store.getters['reports/getAdsTotalRevenue']
    },
    singleDevice() {
      return this.$store.getters['reports/getDevice']
    },
    paginationObject() {
      return {
        offset: this.$store.getters['reports/getOffset'],
        total: this.$store.getters['reports/getTotal'],
        limit: this.$store.getters['reports/getLimit'],
      }
    },
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
  },
  async mounted() {
    await this.loadData()
  },
  async created() {
    const { id } = this.$route.params
    const { groupId } = this.$route.query
    if (this.userRole === this.AccountTypes.ADMIN) {
      await this.getSingleDeviceAdmin({ userId: groupId }, id)
    } else {
      await this.getSingleDeviceUser({ userId: groupId }, id)
    }
  },
  beforeDestroy() {
    this.$store.commit('reports/RESET_SCREEN_DATA')
  },
  methods: {
    async loadData() {
      this.spinner = true
      try {
        const success = await this.$store.dispatch('reports/getScreenData', { deviceId: this.$route.params.id })
        if (success.statusCode === 200) {
          showToast('Fetched Ads successfully', success.message.toString(), 'success')
        }
        this.spinner = false
      } catch (e) {
        console.error(e)
        this.spinner = false
        throw Error(e)
      }
    },
    async getSingleDeviceAdmin({ userId }, id) {
      this.deviceLoad = true
      try {
        const success = await this.$store.dispatch('reports/adminDeviceData', { userId, deviceId: id })
        if (success) {
          this.device = this.singleDevice
          this.deviceLoad = false
          showToast('Fetch Venue', 'Fetch venue successfully', 'success')
        }
      } catch (e) {
        this.handleCatchError(e)
      }
    },
    async getSingleDeviceUser({ userId }, id) {
      this.deviceLoad = true
      try {
        const success = await this.$store.dispatch('reports/userDeviceData', { userId, id })
        if (success) {
          showToast('Fetch Venues', 'Venue fetch successfully', 'success')
          this.device = this.singleDevice
          this.deviceLoad = false
        }
      } catch (e) {
        this.handleCatchError(e)
      }
    },
    handleCatchError(error) {
      this.$swal({
        title: '<span class="font-weight-bolder">Venue not found!</span>',
        icon: 'error',
        html: `${error.message}`,
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: 'Go to Home page',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        customClass: {
          confirmButton: 'btn btn-primary',
          icon: 'primary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$router.push('/report')
        }
      })
    },
    time(value) {
      return timeConverter(value)
    },
    perPageChanged(limit) {
      this.$store.dispatch('reports/setPaginatedScreenData', { offset: 1, limit })
    },
    pageChanged(offset) {
      this.$store.dispatch('reports/setPaginatedScreenData', { offset, limit: this.paginationObject.limit })
    },
  },
}
</script>
